import React, { useState, useEffect } from "react";
import { HelpBlock, FormGroup, ControlLabel, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import ReservationDetails from "./ReservationDetails";
import ReservationDivider from "./ReservationDivider";
import moment from "moment-timezone";
import { DEFAULT_HOURS, DEFAULT_RESERVATION_DURATION } from "../../constants";
import { Checkbox } from "@material-ui/core";
import { validatePhoneNumber } from "../../util/phoneUtils";

function AddReservationForm(props) {
  const { reservationData, bookNow, addToWaitlist, timezone, resources, closed } = props;
  const [selectedDay, setSelectedDay] = useState(getDayTime());
  const [selectedResource, setSelectedResource] = useState();
  const [selectedTime, setSelectedTime] = useState(getDayTime(true));
  const { register, handleSubmit, errors } = useForm();
  const { register: registerBooking, handleSubmit: handleSubmitBooking, errors: bookingErrors } = useForm();
  const emptyForm = {
    phone: "",
    category: ""
  };
  const emptyBookForm = {
    phone: "",
    selectedDay: "",
    selectedTime: "",
    selectedResource: "",
    make: reservationData.make,
    model: reservationData.model,
    color: reservationData.color
  };
  const [form] = useState({ ...emptyForm });
  const [bookingForm] = useState({ ...emptyBookForm });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsAcceptedBooking, setTermsAcceptedBooking] = useState(false);

  useEffect(() => {
    let resource = reservationData.selectedResource;
    if (!resource) {
      resource =
        reservationData.resources && reservationData.resources.length === 1 ? reservationData.resources[0].name : "";
    }
    setSelectedResource(resource);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTermsAccepted = event => {
    setTermsAccepted(event.target.checked);
  };
  const updateTermsAcceptedBooking = event => {
    setTermsAcceptedBooking(event.target.checked);
  };

  function getDayTime(isTime) {
    if (reservationData && reservationData.reservationTime) {
      return moment(reservationData.reservationTime)
        .tz(timezone)
        .format(isTime ? "H:mm" : "MM/DD/YYYY");
    }
    return "";
  }

  function categoryDisplayName(name) {
    if (name && name.toLowerCase() === "drive thru") {
      return name + " (No Modifications)";
    } else {
      return name;
    }
  }

  function changeDate(value) {
    setSelectedDay(value);
    const results = getTimeOptions(value);
    if (results && results.length === 1) {
      setSelectedTime(results[0]);
    } else {
      setSelectedTime("");
    }
  }

  function findResource(selectedRes) {
    for (let r of resources) {
      if (r.name === selectedRes) {
        return r;
      }
    }
  }

  function getTimeOptions(optionalSelectedDay) {
    let r = selectedResource;
    if (resources && resources.length === 1) {
      r = resources[0].name;
    }
    let resource = findResource(r);
    const result = [];
    let day = selectedDay;
    if (optionalSelectedDay) {
      day = optionalSelectedDay;
    }
    if (!day) {
      return result;
    }

    const workingHours = reservationData.workingHours || DEFAULT_HOURS;
    const dayOfWeek = moment(day, "MM/DD/YYYY").day();
    const hours = workingHours[dayOfWeek];

    if (resource.minDuration === -1) {
      const now = moment();
      const hours = workingHours[dayOfWeek];
      const s = moment(day + " " + hours.begin, "MM/DD/YYYY H:mm");
      const e = moment(day + " " + hours.end, "MM/DD/YYYY H:mm");
      if (now.isAfter(e)) {
        return [];
      }
      return [
        {
          disabled: false,
          key: s.format("H:mm"),
          label: `${s.format("h:mm a")} - ${e.format("h:mm a")}`
        }
      ];
    }

    let i = 0;
    let start = moment(hours.begin, "H:mm");
    const end = hours.end;
    while (start.isBefore(moment(end, "H:mm")) && i < 1000) {
      i++;
      let disabled = false;
      const dateTime = moment.tz(`${day} ${start.format("H:mm")}`, "MM/DD/YYYY H:mm", timezone);
      if (isDateBooked(dateTime)) {
        disabled = true;
      }
      const option = {
        disabled,
        key: start.format("H:mm"),
        label: `${start.format("h:mm a")}`
      };
      if (
        !moment
          .tz(timezone)
          .add(15, "minutes")
          .isAfter(dateTime) &&
        !isDateBooked(dateTime)
      ) {
        result.push(option);
      }
      start = start.add(resource.minDuration || DEFAULT_RESERVATION_DURATION, "minutes");
    }
    return result;
  }

  function isDateBooked(dateTime) {
    if (reservationData.bookedDates) {
      let bookedDates =
        resources.length === 1
          ? reservationData.bookedDates[Object.keys(reservationData.bookedDates)[0]]
          : reservationData.bookedDates[selectedResource];
      return bookedDates.some(date => {
        return moment(date).isSame(dateTime);
      });
    }
    return false;
  }

  function isDayDisabled(isToday) {
    const holidays = ["11/26/2020", "12/25/2020"];
    let date = moment.tz(timezone);
    if (!isToday) {
      date = date.add(1, "day");
    }
    return holidays.some(holiday => holiday === date.format("MM/DD/YYYY"));
  }

  function getDateOptionValue(isToday) {
    let date = moment.tz(timezone);
    if (!isToday) {
      date = date.add(1, "day");
    }
    return date.format("MM/DD/YYYY");
  }

  const onSetSelectedResource = event => {
    setSelectedResource(event.target.value);
    setSelectedTime("");
  };

  return (
    <div className="reservation-form">
      <ReservationDetails reservationData={reservationData} appearance={props.appearance} type="registration" />
      <div className="reservation-form-wrapper">
        {reservationData.selfBooking && (
          <Form noValidate className="add-guest" onSubmit={handleSubmitBooking(bookNow)}>
            <div className="reservation-form__schedule">
              <div className="reservation-form__title">
                <span style={{ color: props.appearance.highlightBackgroundColor }}>NEW! </span>Schedule your pickup
              </div>
              {resources && resources.length > 0 && (
                <div className="reservation-form__select">
                  <select
                    style={{
                      fontFamily: props.appearance.fontFamily,
                      borderColor: props.appearance.fontColor,
                      color: props.appearance.fontColor
                    }}
                    value={selectedResource}
                    onChange={onSetSelectedResource}
                    className="form-control"
                    ref={registerBooking({ required: false })}
                    placeholder="Select a Date"
                    name="selectedResource"
                  >
                    <option value="" disabled>
                      Select an Option
                    </option>
                    {resources.map((category, index) => (
                      <option key={index} value={category.name}>
                        {categoryDisplayName(category.name)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="reservation-form__select">
                <select
                  style={{
                    fontFamily: props.appearance.fontFamily,
                    borderColor: props.appearance.fontColor,
                    color: props.appearance.fontColor
                  }}
                  value={selectedDay}
                  onChange={e => changeDate(e.target.value)}
                  className="form-control"
                  ref={registerBooking({ required: false })}
                  placeholder="Select a Date"
                  name="selectedDate"
                >
                  <option value="" disabled>
                    Select a Date
                  </option>
                  {(selectedResource || resources.length === 1) && (
                    <option disabled={isDayDisabled(true)} value={getDateOptionValue(true)}>
                      Today
                    </option>
                  )}
                  {(selectedResource || resources.length === 1) && (
                    <option disabled={isDayDisabled()} value={getDateOptionValue()}>
                      Tomorrow
                    </option>
                  )}
                </select>
              </div>
              <div className="reservation-form__select">
                <select
                  style={{
                    fontFamily: props.appearance.fontFamily,
                    borderColor: props.appearance.fontColor,
                    color: props.appearance.fontColor
                  }}
                  value={selectedTime}
                  onChange={e => setSelectedTime(e.target.value)}
                  className="form-control"
                  ref={registerBooking({ required: false })}
                  placeholder="Select a Date"
                  name="selectedTime"
                >
                  <option value="" disabled>
                    Select a Time
                  </option>
                  {(selectedResource || resources.length === 1) &&
                    getTimeOptions().map((time, i) => (
                      <option key={i} disabled={time.disabled} value={time.key}>
                        {time.label}
                      </option>
                    ))}
                </select>
              </div>
              {selectedResource === "Curbside" && (
                <div className="reservation-form__select">
                  <FormGroup
                    className="add-guest__form-group"
                    style={{ marginBottom: "0px" }}
                    validationState={(!!bookingErrors.make && "error") || null}
                  >
                    <InputMask
                      name="make"
                      className="form-control"
                      style={{ fontSize: "18px", borderColor: "#0b406a" }}
                      type="text"
                      placeholder="Vehicle make"
                      defaultValue={bookingForm.make}
                      ref={registerBooking({ required: true, maxLength: 12 })}
                    />
                    {bookingErrors.make && <HelpBlock>Vehicle make required</HelpBlock>}
                  </FormGroup>
                </div>
              )}
              {selectedResource === "Curbside" && (
                <div className="reservation-form__select">
                  <FormGroup
                    className="add-guest__form-group"
                    style={{ marginBottom: "0px" }}
                    validationState={(!!bookingErrors.model && "error") || null}
                  >
                    <InputMask
                      name="model"
                      className="form-control"
                      style={{ fontSize: "18px", borderColor: "#0b406a" }}
                      type="text"
                      placeholder="Vehicle model"
                      defaultValue={bookingForm.model}
                      ref={registerBooking({ required: true, maxLength: 12 })}
                    />
                    {bookingErrors.make && <HelpBlock>Vehicle model required</HelpBlock>}
                  </FormGroup>
                </div>
              )}
              {selectedResource === "Curbside" && (
                <div className="reservation-form__select">
                  <FormGroup
                    className="add-guest__form-group"
                    style={{ marginBottom: "0px" }}
                    validationState={(!!bookingErrors.color && "error") || null}
                  >
                    <InputMask
                      name="color"
                      className="form-control"
                      style={{ fontSize: "18px", borderColor: "#0b406a" }}
                      type="text"
                      placeholder="Vehicle color"
                      defaultValue={bookingForm.color}
                      ref={registerBooking({ required: true, maxLength: 12 })}
                    />
                    {bookingErrors.make && <HelpBlock>Vehicle color required</HelpBlock>}
                  </FormGroup>
                </div>
              )}
              {!reservationData.gaveConsent && (
                <FormGroup className="add-guest__form-group" style={{ flexDirection: "row" }}>
                  <Checkbox
                    size="medium"
                    checked={termsAcceptedBooking}
                    onChange={updateTermsAcceptedBooking}
                    name="accepted"
                  />
                  <span style={{ fontSize: "12px", textAlign: "left" }}>
                    {" "}
                    By checking the box, I consent to receiving emails from the Waitly Platform on behalf of Trulieve.
                    See our{" "}
                    <a style={{ color: "#4D6CC1" }} href="https://waitly.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    here.
                  </span>
                </FormGroup>
              )}
              <div className="reservation-form__button" />
              <Button
                style={{ fontFamily: props.appearance.fontFamily, background: props.appearance.fontColor }}
                className="reservation-button"
                type="submit"
                disabled={
                  !selectedDay ||
                  !selectedTime ||
                  selectedTime === "" ||
                  (!termsAcceptedBooking && !reservationData.gaveConsent)
                }
              >
                Book Now
              </Button>
            </div>
          </Form>
        )}
        {reservationData.selfBooking && reservationData.waitlistIsOpen && (
          <ReservationDivider appearance={props.appearance} />
        )}
        {reservationData.waitlistIsOpen && !closed && reservationData.selfCheckIn && (
          <div className="reservation-form__waitlist">
            <div className="reservation-form__title">I'm on my way</div>
            <div className="reservation-form__current-wait">
              <div className="reservation-form__current-wait-label">Current Wait:</div>
              <div className="reservation-form__current-wait-time">{reservationData.currentWait} minutes</div>
            </div>
            <Form noValidate className="add-guest" onSubmit={handleSubmit(addToWaitlist)}>
              <div className="reservation-form__select">
                <FormGroup className="add-guest__form-group" validationState={(!!errors.category && "error") || null}>
                  <ControlLabel>
                    How would you like to pick up your order today? Please select preferred check-in option below.
                  </ControlLabel>
                  <select
                    style={{
                      fontFamily: props.appearance.fontFamily,
                      borderColor: props.appearance.fontColor,
                      color: props.appearance.fontColor
                    }}
                    className="form-control"
                    ref={register({ required: true })}
                    placeholder="Select an option"
                    name="category"
                  >
                    <option value="">Select an Option</option>
                    {reservationData.pickupOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {errors.category && <HelpBlock>Please make a selection</HelpBlock>}
                </FormGroup>
              </div>
              {!reservationData.gaveConsent && (
                <FormGroup className="add-guest__form-group" style={{ flexDirection: "row" }}>
                  <Checkbox size="medium" checked={termsAccepted} onChange={updateTermsAccepted} name="accepted" />
                  <span style={{ fontSize: "12px", textAlign: "left" }}>
                    {" "}
                    By checking the box, I consent to receiving emails from the Waitly Platform on behalf of Trulieve.
                    See our{" "}
                    <a style={{ color: "#4D6CC1" }} href="https://waitly.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    here.
                  </span>
                </FormGroup>
              )}
              <FormGroup className="add-guest__form-group" style={{ alignItems: "center" }}>
                <Button
                  style={{ fontFamily: props.appearance.fontFamily, background: props.appearance.fontColor }}
                  className="reservation-button"
                  type="submit"
                  disabled={!termsAccepted && !reservationData.gaveConsent}
                >
                  Add Me to the Waitlist
                </Button>
              </FormGroup>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddReservationForm;
